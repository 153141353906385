import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

import Separator from "@/components/styled/Separator"
import ContentRightImg from "@/components/ContentRightImg"

import { H2, Mision as Styled } from "./styled"

const Mision = () => {
  const query = useStaticQuery(graphql`
    query {
      allDataJson(filter: {page: {eq: "nosotros"}, section: {eq: "mision"}}) {
        nodes {
          ...DefaultImage
        }
      }
    }
  `)
  const [image] = query.allDataJson.nodes[0].images
  return (
    <ContentRightImg image={image}>
      <Styled>
        <div >
          <Separator
            width="max(8%, 4rem)"
            height="100%"
            x="0"
            y="0"
            mobile={{
              width: "2.5rem",
              height: "260px",
              x: "-4px",
              y: "75%"
            }}
          />
          <H2>Nuestra Misión</H2>
          <p>
            Nuestra prioridad cómo empresa es ofrecerte la tranquilidad en el cumplimiento del presupuesto y los plazos pactados. Tenemos cómo misión el espacio, el minimalismo y la optimización de los espacios, siempre enfocados en crear experiencias confortables.
          </p>
        </div>
      </Styled>

    </ContentRightImg>
  )
}

export default Mision