import React from 'react'
import { Vision as Styled } from "./styled"

import { GatsbyImage, getImage } from 'gatsby-plugin-image'

const Vision = ({ image, children, paddingTop, paddingBottom }) => {
  const data = getImage(image)
  return (
    <Styled style={{ paddingTop: paddingTop, paddingBottom: paddingBottom }}>
      <GatsbyImage
        image={data}
        alt="Vision"
      />
      {children}
    </Styled>
  )
}

export default Vision