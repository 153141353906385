import React from 'react'
import { graphql } from 'gatsby'

import Layout from '@/components/layout'
import Seo from '@/components/seo'

import Historia from "../templates/nosotros/Historia"
import VisionContent from "../templates/nosotros/VisionContent"
import Mision from "../templates/nosotros/Mision"
import Vista from "../templates/nosotros/Vista"
import BottomBG from "@/components/BottomBG"



const NosotrosBody = ({ data }) => {
  const [image] = data.allDataJson.nodes[0].images
  return (
    <Layout>
      <Historia />
      <VisionContent />
      <Mision />
      <Vista />
      <BottomBG image={image} />
    </Layout>
  )
}
export const Head = () => <Seo title="Nosotros" />

export const query = graphql`
  query {
    allDataJson(filter: {page: {eq: "nosotros"}, section: {eq: "bottom"}}) {
      nodes {
        ...DefaultImage
      }
    }
  }
`

export default NosotrosBody