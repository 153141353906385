import React from 'react'
import { Wrapper } from "./styled"
import { GatsbyImage, getImage } from 'gatsby-plugin-image';




const ContentRightImg = ({ children, image, alt }) => {
  return (
    <Wrapper>
      {children}
      <div className="img-right">
        <GatsbyImage
          image={getImage(image)}
          style={{ objectPosition: "30%", objectFit: "cover" }}
          alt={alt ?? ""}
          sizes="(max-width: 768px) 100vw, 75vw, 1200px"
          />
      </div>
    </Wrapper>
  )
}

export default ContentRightImg