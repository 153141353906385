import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

import Separator from "@/components/styled/Separator"

import { Vista as Styled } from "./styled" 

const Vista = () => {
  const query = useStaticQuery(graphql`
    query {
      allDataJson(filter: {page: {eq: "nosotros"}, section: {eq: "vista"}}) {
        nodes {
          ...DefaultImage
        }
      }
    }
  `)
  const image = getImage(query.allDataJson.nodes[0].images[0])
  
  return (
    <Styled>
      <Separator
        width="27vw"
        height="46px"
        x="0"
        y="-23px"
        opacity="0.7"
        mobile={{
          width: "max(60vw, 260px)",
          height: "46px",
          x: "0",
          y: "-23px"
        }}
      />
      <GatsbyImage
        image={image}
        alt="Vista"
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          zIndex: -1,
        }}
      />
      <div style={{
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        background: "linear-gradient(to bottom, rgba(0, 0, 0, 0) 10%, rgba(0, 0, 0, .7) 75%)",
      }}/>
      <div className="text">
        <h3>Pinamar, Ostende, Valeria del Mar, Cariló, Costa Esmeralda</h3>
      </div>
    </Styled>
  )
}

export default Vista