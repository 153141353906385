import styled from "styled-components";

export const Historia = styled.article`
  /* height: 90vh; */
  width: 100%;
  /* margin-bottom: 4rem; */

  padding-top: 100px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  



  .title {

    display: flex;
    flex-direction: column;
    align-items: center;

    gap: 3rem;

    img {
      width: 360px;
      width: min(360px, 80%);
    }
    h1 {
      font-size: max(2.73rem, 9vw);

      line-height: max(1.9rem, 6.256vw);
      text-align: center;

      color: var(--color-primary);
      span {
        color: var(--color-text);
        /* font-size: 5vw;
        font-size: min(1.7rem, 6vw); */
        font-size: 1.9rem;
        font-size: max(1.9rem, 6.256vw);




      }
    }
  }
  .collage {
    position: relative;
    margin-top: 2rem;

    width: 100vw;
    height: 100%;
    
  }
  @media (min-width: 768px) {
    height: 100%;
    min-height: 90vh;
    margin-bottom: 0;
    padding-top: 70px;

    align-items: center;
    justify-content: flex-end;
    flex-direction: row;
    .title {
      position: absolute;
      left: 0;

      align-items: flex-start;
      margin-left: 4.44vw;

      img {
        width: min(360px, 25vw);
      }
      h1 {
        text-align: left;
        font-size: min(5.33rem, 5.92vw);
        line-height: min(3.195rem, 3vw);

        span {
          font-size: min(3.195rem, 3vw);

        }
      }
    }
    .collage {
      
      margin: 0;
      width: auto;
      height: auto;
      transition: transform 0.5s ease-in-out;

      width: min(871px, 65vw);
      
      .gatsby-image-wrapper {

      }
    }
  }
  @media (min-width: 1024px) {

  }
  @media (min-width: 1280px) {
    .collage{ 
      width: min(1200px, 55vw);
    }
  }

`


export const Mision = styled.article`
    display: flex;
    flex-direction: column;
    justify-content: center;

    > div {
      padding: 6rem 0;


      
      position: relative;
      width: 100vw;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      p {
        text-align: justify;
        font-size:  1rem;


        max-width: 55ch;
        margin: 0 1rem;
        padding-bottom: 2rem;
        padding-top: 1rem;

      }
      
    }


  @media (min-width: 768px) {
    padding: 0;

    > div {
      width: unset;
      padding-left: 10rem;
      padding-left: clamp(5rem, 10vw, 10rem);

      padding-right: 4rem;
      padding-right: clamp(2rem, 4vw, 4rem);
      
      padding-right: 2rem;
      padding-left: 5rem;

      p {
        padding: 0;
        width: 50ch;
        font-size: 1.5625vw;

        

      }
    }
  }
  @media (min-width: 1024px) {
    > div {
      padding-right: 1rem;
      /* padding: 0; */
      padding-left: 5rem;

      p {
        text-align: justify;
        font-size: min(1.39vw, 1.25rem);

        width: 55ch;

      }
    }
  }

`

export const Vista = styled.article`
  position: relative;
  height: 100vh;
  width: 100%;

  display: flex;
  align-items: flex-end;
  justify-content: center;



  .text {
    position: relative;
    padding-bottom: 5rem;
    h3 {
      text-align: center;
      color: var(--color-text-secondary);
      max-width: 30ch;


      font-size: 5vw;
      font-weight: 200;
      
    }
  }

  @media (min-width: 768px) {
    .text {
      padding-bottom: 3rem;
      h3 {
        font-size: 3rem;
      }
    }
  }

  @media (min-width: 1024px) {
    .text {
      h3 {
        font-size: 3.75rem;
      }
    }
  }
`

export const H2 = styled.h2`
  font-size: clamp(2.5rem ,8.33vw, 4rem);
  
  color: var(--color-primary);
  text-align: center;

  @media (min-width: 768px) {
    font-size: min(5.46875vw, 3.5rem);
  }
  @media (min-width: 1024px) {
    font-size: min(4.86vw, 4.375rem);
    color: var(--color-primary);
    text-align: center;
  }
`