import React from 'react'
import Vision from '@/components/Vision'
import styled from 'styled-components'
import { H2 } from './styled'
import { graphql, useStaticQuery } from 'gatsby'

const Content = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 4rem 2rem;
  width: 100%;

  h2 {
    margin-bottom: 1.5rem;

  }
  p {
    white-space: pre-wrap;
    max-width: 60ch;
    text-align: justify;
    font-size: 1rem;
  }
  @media (min-width: 768px) {
    position: absolute;
    right: 0;
    background-color: hsl(0, 0%, 85%, 0.8);

    width: unset;
    h2 {
      font-size: 3.2rem;
    }
    p {
      font-size: 1.125rem;
    }

  }
  @media (min-width: 1024px) {
  padding: 8.5rem 4rem;
  p {
    font-size: 1.261rem;
  }
  }
`

const VisionContent = () => {
  const query = useStaticQuery(graphql`
    query {
      allDataJson(filter: {page: {eq: "nosotros"}, section: {eq: "vision"}}) {
        nodes {
          ...DefaultImage
          text
          title
        }
      }
    }
  `)
  const { title, text, images } = query.allDataJson.nodes[0]

  return (
    <Vision image={images[0]} paddingTop="2rem" paddingBottom="2rem">
      <Content>
        <H2>{title}</H2>
        <p>
          {text}
        </p>
      </Content>
    </Vision>
  )
}

export default VisionContent