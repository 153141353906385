import React from 'react'
import { Historia as Styled } from './styled'

import Logo from "@/assets/Logo.svg"
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

const Historia = () => {
  const query = useStaticQuery(graphql`
    query {
      allDataJson(filter: {page: {eq: "nosotros"}, section: {eq: "historia"}}) {
        nodes {
          ...DefaultImage
        }
      }
    }
  `)
  const image = getImage(query.allDataJson.nodes[0].images[4])

  return (
    <Styled>
      <div className='title'>
        <img src={Logo} alt="Logo Grupo Beltran"
          width="254"
          height="91"
        />
        <h1>30 años <br /><span>siendo parte de la historia</span></h1>
      </div>
      <div className="collage">
        <GatsbyImage
          image={image}
          loading="eager"
          alt="Historia de Pinamar"
        />
      </div>
    </Styled>
  )
}

export default Historia